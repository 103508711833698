.desktop {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.desktop .div {
  background-color: #000000;
  overflow: hidden;
  width: 1440px;
  height: 4178px;
  position: relative;
}

.desktop .frame {
  display: flex;
  width: 1351px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 141px;
  left: 81px;
  opacity: 0; /* Start with the slide hidden */
  transition: opacity 1s ease-in-out; /* Smooth transition for fading */
}

.desktop .frame.active {
  opacity: 1; /* Make the active slide visible */
  z-index: 1; /* Ensure the active slide is on top */
}

.desktop .group {
  position: relative;
  width: 531px;
  height: 442px;
}

.desktop .frame-2 {
  display: flex;
  flex-direction: column;
  width: 531px;
  align-items: flex-start;
  gap: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .a-quiet-place {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: var(--banner-header-font-family);
  font-weight: var(--banner-header-font-weight);
  color: var(--white);
  font-size: var(--banner-header-font-size);
  letter-spacing: var(--banner-header-letter-spacing);
  line-height: var(--banner-header-line-height);
  font-style: var(--banner-header-font-style);
}

.desktop .text-wrapper {
  position: relative;
  align-self: stretch;
  font-family: var(--body-text-font-family);
  font-weight: var(--body-text-font-weight);
  color: #8c8f99;
  font-size: var(--body-text-font-size);
  letter-spacing: var(--body-text-letter-spacing);
  line-height: var(--body-text-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-style: var(--body-text-font-style);
}

.desktop .button {
  all: unset;
  box-sizing: border-box;
  display: flex;
  width: 239px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 14px 24px;
  position: absolute;
  top: 390px;
  left: 0;
  background-color: #ffb43a;
  border-radius: 999px;
  overflow: hidden;
}

.desktop .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Poppins", Helvetica;
  font-weight: 700;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.desktop .button-2 {
  all: unset;
  box-sizing: border-box;
  display: flex;
  width: 234px;
  align-items: center;
  gap: 7px;
  padding: 14px 24px;
  top: 390px;
  left: 257px;
  backdrop-filter: blur(30px) brightness(100%);
  -webkit-backdrop-filter: blur(30px) brightness(100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  border-radius: 999px;
}

.desktop .play {
  position: relative;
  width: 24px;
  height: 24px;
}

.desktop .img {
  position: relative;
  width: 770px;
  height: 752px;
  object-fit: cover;
}

.desktop .top-navigation {
  display: flex;
  width: 1440px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 112px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #07192a;
  backdrop-filter: blur(183px) brightness(100%);
  -webkit-backdrop-filter: blur(183px) brightness(100%);
}

.desktop .cinema-logo {
  position: relative;
  width: 252px;
  height: 101px;
  object-fit: cover;
}

.desktop .wraper {
  position: relative;
  width: 267px;
  height: 48px;
}

.desktop .label-wrapper {
  all: unset;
  box-sizing: border-box;
  width: 124px;
  height: 48px;
  top: 0;
  left: 0;
  background-color: #ffb43a;
  position: absolute;
  border-radius: 999px;
}

.desktop .label {
  color: #ffffff;
  position: absolute;
  top: 11px;
  left: 35px;
  font-family: "Poppins", Helvetica;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
  white-space: nowrap;
}

.desktop .div-wrapper {
  all: unset;
  box-sizing: border-box;
  width: 131px;
  height: 48px;
  top: 0;
  left: 136px;
  border: 1px solid;
  border-color: #9998ad;
  position: absolute;
  border-radius: 999px;
}

.desktop .label-2 {
  color: #9998ad;
  position: absolute;
  top: 11px;
  left: 35px;
  font-family: "Poppins", Helvetica;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
  white-space: nowrap;
}

.desktop .menu {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  position: absolute;
  top: 58px;
  left: 495px;
}

.desktop .home {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Poppins", Helvetica;
  font-weight: 500;
  color: var(--white);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.desktop .text-wrapper-3 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #9998ad;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.desktop .frame-3 {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: 952px;
  left: 664px;
}

.desktop .rectangle {
  position: relative;
  width: 16px;
  height: 8px;
  background-color: #ffb43a;
  border-radius: 300px;
}

.desktop .rectangle-2 {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: #d9d9d9;
  border-radius: 300px;
}

.desktop .frame-4 {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: 3934px;
  left: 681px;
}

.desktop .overlap {
  position: absolute;
  width: 1438px;
  height: 261px;
  top: 1019px;
  left: -1px;
}

.desktop .frame-5 {
  display: flex;
  width: 1438px;
  height: 180px;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  padding: 48px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0px 0px 100px #000000;
}

.desktop .group-2 {
  position: absolute;
  width: 1318px;
  height: 779px;
  top: -245px;
  left: -100px;
}

.desktop .rectangle-3 {
  position: absolute;
  width: 354px;
  height: 354px;
  top: 425px;
  left: 0;
  background-color: #ffffff;
  filter: blur(744.03px);
}

.desktop .rectangle-4 {
  position: absolute;
  width: 276px;
  height: 276px;
  top: 0;
  left: 44px;
  background-color: #ffb43a;
  filter: blur(501.88px);
}

.desktop .rectangle-5 {
  position: absolute;
  width: 251px;
  height: 251px;
  top: 471px;
  left: 1067px;
  background-color: #ffb43a;
  filter: blur(456.36px);
}

.desktop .text-wrapper-4 {
  position: relative;
  width: 639px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  letter-spacing: 1.68px;
  line-height: 26.4px;
}

.desktop .text-wrapper-5 {
  position: absolute;
  width: 122px;
  top: 163px;
  left: 1285px;
  font-family: "Poppins", Helvetica;
  font-weight: 600;
  color: #fbf2f2;
  font-size: 128px;
  text-align: center;
  letter-spacing: 2.56px;
  line-height: 64px;
}

.desktop .frame-6 {
  display: flex;
  width: 1438px;
  height: 180px;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  padding: 48px;
  position: absolute;
  top: 3325px;
  left: 1px;
  overflow: hidden;
  box-shadow: 0px 0px 100px #000000;
}

.desktop .frame-7 {
  display: flex;
  flex-wrap: wrap;
  width: 1189px;
  height: 1988px;
  align-items: flex-start;
  gap: 35px 35px;
  position: absolute;
  top: 1281px;
  left: 123px;
}

.desktop .rectangle-6 {
  position: relative;
  width: 373px;
  height: 467px;
  object-fit: cover;
  margin-bottom: 0px;
}

.desktop .wraper-2 {
  display: flex;
  flex-direction: column;
  width: 553px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 3649px;
  left: 101px;
}

.desktop .westgate-cinema {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Poppins", Helvetica;
  font-weight: 600;
  color: #fbf2f2;
  font-size: 40px;
  letter-spacing: 0.8px;
  line-height: 52px;
}

.desktop .ready-for-a {
  position: relative;
  align-self: stretch;
  height: 85px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #8c8f99;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.desktop .rectangle-7 {
  position: absolute;
  width: 619px;
  height: 337px;
  top: 3551px;
  left: 710px;
  object-fit: cover;
}

.desktop .footer {
  position: absolute;
  width: 1440px;
  height: 183px;
  top: 3994px;
  left: 0;
  background-color: #07192a;
}

.desktop .westgate-cinema-2 {
  position: absolute;
  top: 77px;
  left: 601px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.desktop .follow-us {
  position: absolute;
  top: 27px;
  left: 1158px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.desktop .download-westgate {
  position: absolute;
  top: 27px;
  left: 123px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.desktop .group-3 {
  position: absolute;
  width: 168px;
  height: 51px;
  top: 73px;
  left: 268px;
}

.desktop .group-4 {
  position: absolute;
  width: 172px;
  height: 51px;
  top: 73px;
  left: 85px;
}

.desktop .group-wrapper {
  display: flex;
  flex-direction: column;
  width: 195px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 65px;
  left: 1130px;
}

.desktop .group-5 {
  position: relative;
  width: 195px;
  height: 53.08px;
}

.desktop .overlap-group {
  position: absolute;
  width: 53px;
  height: 53px;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-radius: 26.54px;
}

.desktop .vector {
  position: absolute;
  width: 17px;
  height: 17px;
  top: 18px;
  left: 18px;
}

.desktop .vector-wrapper {
  position: absolute;
  width: 54px;
  height: 53px;
  top: 0;
  left: 71px;
  background-color: #ffffff;
  border-radius: 26.83px/26.54px;
}

.desktop .vector-2 {
  height: 15px;
  left: 17px;
  position: absolute;
  width: 19px;
  top: 19px;
}

.desktop .img-wrapper {
  position: absolute;
  width: 53px;
  height: 53px;
  top: 0;
  left: 142px;
  background-color: #ffffff;
  border-radius: 26.54px;
}

.desktop .vector-3 {
  height: 16px;
  left: 16px;
  position: absolute;
  width: 19px;
  top: 19px;
}
