.showtime-desktop {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.showtime-desktop .div {
  background-color: #000000;
  width: 1440px;
  height: 2887px;
  position: relative;
}

.showtime-desktop .a-quiet-place-day {
  position: absolute;
  top: 253px;
  left: 38px;
  font-family: "Poppins", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 80px;
  letter-spacing: 4px;
  line-height: 92px;
  white-space: nowrap;
}

.showtime-desktop .banner {
  display: flex;
  width: 1378px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 374px;
  left: 32px;
}

.showtime-desktop .rectangle {
  position: relative;
  width: 402px;
  height: 607px;
  object-fit: cover;
}

.showtime-desktop .video-player {
  position: relative;
  width: 945px;
  height: 575px;
  background-color: #000000;
}

.showtime-desktop .dates {
  position: absolute;
  width: 962px;
  height: 147px;
  top: 1533px;
  left: 244px;
}

.showtime-desktop .overlap {
  position: absolute;
  width: 151px;
  height: 145px;
  top: 3px;
  left: 0;
  background-color: #32323b;
  border-radius: 11px;
}

.showtime-desktop .text-wrapper {
  position: absolute;
  top: 90px;
  left: 34px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .text-wrapper-2 {
  position: absolute;
  top: 6px;
  left: 50px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .text-wrapper-3 {
  position: absolute;
  top: 47px;
  left: 62px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .overlap-group {
  position: absolute;
  width: 151px;
  height: 145px;
  top: 3px;
  left: 195px;
  background-color: #32323b;
  border-radius: 11px;
}

.showtime-desktop .text-wrapper-4 {
  position: absolute;
  top: 90px;
  left: 30px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .text-wrapper-5 {
  top: 47px;
  left: 71px;
  position: absolute;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .overlap-2 {
  position: absolute;
  width: 152px;
  height: 145px;
  top: 3px;
  left: 390px;
  background-color: #32323b;
  border-radius: 11px;
}

.showtime-desktop .text-wrapper-6 {
  position: absolute;
  top: 90px;
  left: 31px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .july {
  top: 6px;
  left: 51px;
  position: absolute;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .text-wrapper-7 {
  top: 47px;
  left: 70px;
  position: absolute;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .overlap-3 {
  position: absolute;
  width: 152px;
  height: 145px;
  top: 3px;
  left: 586px;
  background-color: #d9d9d9;
  border-radius: 11px;
}

.showtime-desktop .text-wrapper-8 {
  position: absolute;
  top: 90px;
  left: 13px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .july-2 {
  top: 11px;
  left: 50px;
  position: absolute;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .text-wrapper-9 {
  top: 47px;
  left: 69px;
  position: absolute;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .overlap-4 {
  position: absolute;
  width: 151px;
  height: 145px;
  top: 0;
  left: 781px;
  background-color: #32323b;
  border-radius: 11px;
}

.showtime-desktop .thursday {
  position: absolute;
  top: 93px;
  left: 23px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .july-3 {
  top: 9px;
  left: 52px;
  position: absolute;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .text-wrapper-10 {
  top: 50px;
  left: 70px;
  position: absolute;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .runtime {
  position: absolute;
  top: 1017px;
  left: 1126px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .movie-info {
  position: absolute;
  width: 1317px;
  top: 1119px;
  left: 61px;
  font-family: var(--body-text-font-family);
  font-weight: var(--body-text-font-weight);
  color: #8c8f99;
  font-size: var(--body-text-font-size);
  letter-spacing: var(--body-text-letter-spacing);
  line-height: var(--body-text-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-style: var(--body-text-font-style);
}

.showtime-desktop .rating {
  position: absolute;
  width: 140px;
  height: 52px;
  top: 1018px;
  left: 937px;
}

.showtime-desktop .overlap-5 {
  position: relative;
  width: 138px;
  height: 52px;
}

.showtime-desktop .rectangle-2 {
  position: absolute;
  width: 138px;
  height: 40px;
  top: 6px;
  left: 0;
  background-color: #d9d9d9;
}

.showtime-desktop .rating-2 {
  position: absolute;
  top: 0;
  left: 9px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .genre {
  position: absolute;
  width: 140px;
  height: 52px;
  top: 1018px;
  left: 318px;
}

.showtime-desktop .text-wrapper-11 {
  position: absolute;
  top: 0;
  left: 19px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .rectangle-3 {
  position: absolute;
  width: 138px;
  height: 40px;
  top: 6px;
  left: 0;
  border: 1px solid;
  border-color: #ffffff;
}

.showtime-desktop .overlap-wrapper {
  left: 175px;
  position: absolute;
  width: 140px;
  height: 52px;
  top: 1018px;
}

.showtime-desktop .text-wrapper-12 {
  position: absolute;
  top: 0;
  left: 26px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .overlap-group-wrapper {
  left: 32px;
  position: absolute;
  width: 140px;
  height: 52px;
  top: 1018px;
}

.showtime-desktop .sci-fi {
  position: absolute;
  top: 0;
  left: 33px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .premium-timing {
  position: absolute;
  width: 916px;
  height: 191px;
  top: 1836px;
  left: 254px;
}

.showtime-desktop .group {
  position: absolute;
  width: 924px;
  height: 51px;
  top: 140px;
  left: 0;
}

.showtime-desktop .element-wrapper {
  position: absolute;
  width: 177px;
  height: 51px;
  top: 0;
  left: 0;
  background-color: #32323b;
}

.showtime-desktop .element {
  position: absolute;
  width: 114px;
  top: 0;
  left: 43px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .element-pm-wrapper {
  position: absolute;
  width: 177px;
  height: 51px;
  top: 0;
  left: 240px;
  background-color: #32323b;
}

.showtime-desktop .element-pm {
  position: absolute;
  width: 119px;
  top: 0;
  left: 38px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .div-wrapper {
  width: 177px;
  height: 51px;
  left: 500px;
  background-color: #d9d9d9;
  position: absolute;
  top: 0;
}

.showtime-desktop .element-2 {
  position: absolute;
  width: 110px;
  top: 0;
  left: 41px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .overlap-6 {
  position: absolute;
  width: 177px;
  height: 51px;
  top: 0;
  left: 739px;
  background-color: #32323b;
}

.showtime-desktop .element-3 {
  position: absolute;
  width: 88px;
  top: 0;
  left: 46px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .group-2 {
  position: absolute;
  width: 384px;
  height: 51px;
  top: 0;
  left: 0;
}

.showtime-desktop .overlap-7 {
  position: absolute;
  width: 177px;
  height: 51px;
  top: 0;
  left: 0;
  background-color: #ffb43a;
}

.showtime-desktop .premium {
  position: absolute;
  width: 110px;
  top: 0;
  left: 33px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .element-kshs {
  position: absolute;
  width: 178px;
  top: 0;
  left: 202px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffb43a;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .VIP-timing {
  position: absolute;
  width: 916px;
  height: 187px;
  top: 2183px;
  left: 254px;
}

.showtime-desktop .group-3 {
  position: absolute;
  width: 924px;
  height: 51px;
  top: 136px;
  left: 0;
}

.showtime-desktop .overlap-8 {
  width: 177px;
  height: 51px;
  left: 500px;
  background-color: #32323b;
  position: absolute;
  top: 0;
}

.showtime-desktop .element-4 {
  position: absolute;
  width: 97px;
  top: 0;
  left: 46px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .vip {
  position: absolute;
  width: 47px;
  top: 0;
  left: 66px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .book-seats {
  position: absolute;
  width: 242px;
  height: 58px;
  top: 2573px;
  left: 600px;
}

.showtime-desktop .overlap-9 {
  position: relative;
  width: 240px;
  height: 58px;
  background-color: #ffb43a;
}

.showtime-desktop .book-seats-2 {
  position: absolute;
  top: 3px;
  left: 28px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 52px;
  white-space: nowrap;
}

.showtime-desktop .arrow {
  position: absolute;
  width: 24px;
  height: 15px;
  top: 22px;
  left: 187px;
}

.showtime-desktop .top-navigation {
  display: flex;
  width: 1440px;
  height: 157px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 112px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #07192a;
  backdrop-filter: blur(183px) brightness(100%);
  -webkit-backdrop-filter: blur(183px) brightness(100%);
}

.showtime-desktop .cinema-logo {
  position: relative;
  width: 252px;
  height: 101px;
  object-fit: cover;
}

.showtime-desktop .wraper {
  position: relative;
  width: 267px;
  height: 48px;
}

.showtime-desktop .button {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  width: 124px;
  height: 48px;
  top: 0;
  left: 0;
  background-color: #ffb43a;
  border-radius: 999px;
}

.showtime-desktop .label {
  position: absolute;
  top: 11px;
  left: 35px;
  font-family: "Poppins", Helvetica;
  font-weight: 600;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
  white-space: nowrap;
}

.showtime-desktop .label-wrapper {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  width: 131px;
  height: 48px;
  top: 0;
  left: 136px;
  border-radius: 999px;
  border: 1px solid;
  border-color: #9998ad;
}

.showtime-desktop .label-2 {
  position: absolute;
  top: 11px;
  left: 35px;
  font-family: "Poppins", Helvetica;
  font-weight: 600;
  color: #9998ad;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
  white-space: nowrap;
}

.showtime-desktop .menu {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  position: absolute;
  top: 72px;
  left: 495px;
}

.showtime-desktop .home {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Poppins", Helvetica;
  font-weight: 500;
  color: var(--white);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.showtime-desktop .text-wrapper-13 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #9998ad;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.showtime-desktop .select-date {
  display: flex;
  width: 1438px;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  padding: 48px;
  position: absolute;
  top: 1259px;
  left: 0;
  overflow: hidden;
  box-shadow: 0px 0px 100px #000000;
}

.showtime-desktop .group-4 {
  position: absolute;
  width: 1318px;
  height: 779px;
  top: -245px;
  left: -100px;
}

.showtime-desktop .rectangle-4 {
  position: absolute;
  width: 354px;
  height: 354px;
  top: 425px;
  left: 0;
  background-color: #ffffff;
  filter: blur(744.03px);
}

.showtime-desktop .rectangle-5 {
  position: absolute;
  width: 276px;
  height: 276px;
  top: 0;
  left: 44px;
  background-color: #ffb43a;
  filter: blur(501.88px);
}

.showtime-desktop .rectangle-6 {
  position: absolute;
  width: 251px;
  height: 251px;
  top: 471px;
  left: 1067px;
  background-color: #ffb43a;
  filter: blur(456.36px);
}

.showtime-desktop .select-the-date {
  position: relative;
  width: 639px;
  margin-top: -1px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  letter-spacing: 1.68px;
  line-height: 26.4px;
}

.showtime-desktop .img {
  position: absolute;
  width: 46px;
  height: 12px;
  top: 1601px;
  left: 159px;
}

.showtime-desktop .arrow-2 {
  position: absolute;
  width: 47px;
  height: 15px;
  top: 1600px;
  left: 1233px;
}

.showtime-desktop .footer {
  position: absolute;
  width: 1440px;
  height: 183px;
  top: 2704px;
  left: 0;
  background-color: #07192a;
}

.showtime-desktop .westgate-cinema {
  position: absolute;
  top: 77px;
  left: 601px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.showtime-desktop .follow-us {
  position: absolute;
  top: 27px;
  left: 1158px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.showtime-desktop .download-westgate {
  position: absolute;
  top: 27px;
  left: 123px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.showtime-desktop .group-5 {
  position: absolute;
  width: 168px;
  height: 51px;
  top: 73px;
  left: 268px;
}

.showtime-desktop .group-6 {
  position: absolute;
  width: 172px;
  height: 51px;
  top: 73px;
  left: 85px;
}

.showtime-desktop .frame {
  display: flex;
  flex-direction: column;
  width: 195px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 65px;
  left: 1130px;
}

.showtime-desktop .group-7 {
  position: relative;
  width: 195px;
  height: 53.08px;
}

.showtime-desktop .vector-wrapper {
  width: 53px;
  height: 53px;
  left: 0;
  background-color: #ffffff;
  border-radius: 26.54px;
  position: absolute;
  top: 0;
}

.showtime-desktop .vector {
  position: absolute;
  width: 17px;
  height: 17px;
  top: 18px;
  left: 18px;
}

.showtime-desktop .img-wrapper {
  position: absolute;
  width: 54px;
  height: 53px;
  top: 0;
  left: 71px;
  background-color: #ffffff;
  border-radius: 26.83px/26.54px;
}

.showtime-desktop .vector-2 {
  height: 15px;
  left: 17px;
  position: absolute;
  width: 19px;
  top: 19px;
}

.showtime-desktop .overlap-10 {
  width: 53px;
  height: 53px;
  left: 142px;
  background-color: #ffffff;
  border-radius: 26.54px;
  position: absolute;
  top: 0;
}

.showtime-desktop .vector-3 {
  height: 16px;
  left: 16px;
  position: absolute;
  width: 19px;
  top: 19px;
}
