:root {
  --white: rgba(255, 255, 255, 1);
  --body-text-font-family: "Poppins", Helvetica;
  --body-text-font-weight: 400;
  --body-text-font-size: 24px;
  --body-text-letter-spacing: 0px;
  --body-text-line-height: 32px;
  --body-text-font-style: normal;
  --banner-header-font-family: "Poppins", Helvetica;
  --banner-header-font-weight: 700;
  --banner-header-font-size: 80px;
  --banner-header-letter-spacing: 1.6px;
  --banner-header-line-height: 92px;
  --banner-header-font-style: normal;
}
